<template>
    <div>
        <b-alert class="position-fixed" v-model="showAlert" dismissible v-bind:variant="msg.success ? 'success' : 'danger'">
            {{ msg.text }}
        </b-alert>
    </div>
</template>

<script>
export default {
    props:['msg'],
    watch:{
        msg: function(){
            this.showAlert = this.msg.countdown || 5 // time (seconds) the modal will be shown
        }
    },
    data(){
        return{
            showAlert:false,
        }
    }
}
</script>

<style>
    .alert .close{
        background-color: transparent;
        border: none;
        font-size: 1.25em;
    }
</style>

<style scoped>
    .alert{
        top: 2em;
        right: 2em;
        z-index: 1059;
    }
</style>