<template>
    <span>
        <b-button class="backToTop bg-white text-secondary rounded-circle border-0 p-2 align-items-center justify-content-center darkenOnHover" @click="scrollTop()">
            <b-icon icon="arrow-up" font-scale="1.4em"></b-icon>
        </b-button>
    </span>
</template>

<script>
export default {
    mounted(){
        this.$nextTick(function() {
            const content = document.querySelector('#content')

            if (content)
                content.onscroll = () => {this.scrollFunction()}
        })
    },
    methods: {
        scrollFunction() { // shows button when user scrolls to more than 25% of the screen
            const button = document.querySelector(".backToTop");
            const content = document.querySelector('#content')
            const height = document.documentElement.clientHeight || window.innerHeight || document.body.clientHeight

            if (button) {
                if (content?.scrollTop > (0.25 * height)) {
                    button.style.display = "flex";
                } else {
                    button.style.display = "none";
                }
            }
        },
        scrollTop() {
            const content = document.getElementById('content')

            if (content)
                content.scrollTop = 0
        }
    },
    data(){
        return {

        }
    }
}
</script>

<style scoped>
    .backToTop{
        color: #aaa;
        outline: none;
        position: fixed;
        right: 1.5em;
        bottom: 3em;
        box-shadow: 3px 3px 8px #aaa;
        z-index: 10;
        opacity: 0.75;
        display: none;
    }
    .backToTop:hover{
        opacity: 1;
    }
    @media(max-width:768px){
        .backToTop{
            margin: 1em 0;
        }
    }
</style>