import Vue from "vue"
import moment from 'moment';
import 'moment/locale/pt-br';

Vue.filter("date", ( date, format, isLocaltime = false ) => {
    moment().locale('pt-br')
    if(date || date === 0) {
        if(format === 'DD:HH:mm:ss') {
            let duration = moment.duration(date);
            let days = duration.days();
            if(isLocaltime) {
                return moment(new Date(date)).utc().format(days > 0 ? 'DD:HH:mm:ss' : 'HH:mm:ss');
            } else {
                return moment(new Date(date)).format(days > 0 ? 'DD:HH:mm:ss' : 'HH:mm:ss');
            }
        } else {
            if(isLocaltime) {
                return moment(new Date(date)).utc().format(format)
            } else {
                return moment(new Date(date)).format(format)
            }
        }
    }
    return ''
})

Vue.filter("orderBy", ( array, field ) => {
    return array.slice().sort((a, b) => a[field].localeCompare(b[field])); // slice is used so the array is not mutated, thus preventing infinite loops
})

Vue.filter("capitalize", (v) => {
    if (!v) return '';
    return v.charAt(0).toUpperCase() + v.slice(1);
})

Vue.filter("translateStatus", (status) => {
    let ret = ''
    switch(status) {
        case 'waiting':
            ret = 'Aguardando'
            break;
        case 'offline_operators':
            ret = 'Fila de Espera'
            break;
        case 'in_attendance':
            ret = 'Em atendimento'
            break;
        case 'opened':
            ret = 'Em auto-atendimento'
            break;
        default:
            ret = 'Finalizado'
            break
    }
    return ret
})

Vue.filter('pluralize', (word, amount) => (amount > 1 || amount === 0) ? `${word}s` : word)

Vue.filter('pad', function (value, zeroCount) { // pad a number with leading zeros, if number is bigger than zero
    return value > 0 ? String(value).padStart(zeroCount, '0') : value ;
});

Vue.filter('formatDocument', (document) => {
    if (!document) return '';
    
    if (document.length === 11) { // CPF has 11 digits
        return `${document.substr(0, 3)}.${document.substr(3, 3)}.${document.substr(6, 3)}-${document.substr(9,2)}`;
    } else if (document.length === 14) { // CNPJ has 14 digits
        return `${document.substr(0,2)}.${document.substr(2,3)}.${document.substr(5,3)}/${document.substr(8,4)}-${document.substr(12, 2)}` 
    } else { 
        return document; // Return the original value if it doesn't match CPF or CNPJ format 
    } 
})

Vue.filter('formatNumber', (number) => {
    if(number) {
        let n = number
        let ddi = number.substring(0,2)

        if(ddi != '55')
            number = `55${number}`

        let has9 = false
        if (number?.length == 13 && parseInt(number.charAt(4)) == 9) has9 = true

        if(has9)
            n = [number.slice(0,2),' ',number.slice(2,4),' ',number.slice(4,9),'-',number.slice(9)].join('')
        else
            n = [number.slice(0,2),' ',number.slice(2,4),' ',number.slice(4,8),'-',number.slice(8)].join('')

        return n
    }
    return ''
})

Vue.filter('floatingPoint', (num) => {
    return parseFloat(num.replace(',', '.')).toFixed(2).replace('.', ',')
})

Vue.filter('decimalSpacing', (number) => { // receives a number and returns a string with thousands separators
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
})

Vue.filter('pluralize', (word, amount) => (amount > 1 || amount === 0) ? `${word}s` : word)