function getUser() {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user)
        return user
    return null
}

function getOperator() {
    const operator = JSON.parse(localStorage.getItem('operator'))
    if (operator)
        return operator
    return null
}

function getToken() {
    const token = JSON.parse(localStorage.getItem('token'))
    if (token)
        return token
    return null
}

function getTokenTimestamp() {
    const tokenTimestamp = JSON.parse(localStorage.getItem('tokenTimestamp'))
    if (tokenTimestamp)
        return tokenTimestamp
    return null
}

function logout(socket=null,query=null) {
    localStorage.clear()

    if (socket) {
        socket.disconnect()
    }

    if (query) {
        // console.log(query)
        window.location.replace(`/#/login?${query}`)
    } else {
        window.location.reload()
    }
    // window.location.replace('/#/login')
}

module.exports = {
    getUser, logout, getToken, getTokenTimestamp, getOperator
}