<template>
    <b-row class="appHeader m-0 d-flex justify-content-end px-3" cols="2" id="headerrow">
        <header v-bind:style="{ width: `calc(100% - ${sidebarWidth}px)` }" v-bind:class="{ headerfullscreen: fullscreen }">
            <b-navbar class="d-flex justify-content-between text-purple">
                <div>
                    <div class="fullscreenleftdiv" v-if="fullscreen">
                        <!-- <b-img v-b-toggle.sidebar-1 class="fullscreenlogo" src="https://firebasestorage.googleapis.com/v0/b/gotalk-app.appspot.com/o/assets%2Fgotalk-icon.png?alt=media&token=15049507-887e-4522-a3de-82f9cbe81684"></b-img> -->
                        <!-- <b-img v-b-toggle.sidebar-1 class="fullscreenlogo" :src="require(`../assets/images/logomarca/icone.png`)"></b-img> -->
                        <b-img v-b-toggle.sidebar-1 class="fullscreenlogo" :src="verifyPhotoURL(user.iconURL)"></b-img>
                        <span class="data">
                            Hoje: <span class="text-capitalize mx-1">{{data.dia}}</span> • {{data.hora}}
                        </span>
                        <b-sidebar id="sidebar-1" aria-label="Sidebar" no-header no-title backdrop width="150px">
                            <Sidebar class="h-100" :fullscreen="fullscreen" :user="user" :socket="socket" />
                        </b-sidebar>
                    </div>
                </div>
                <b-navbar-nav v-if="user">
                    <span class="d-flex align-items-center" v-if="icons">
                        <div class="navitems" v-for="item in icons" :key="item.id">
                            <span v-if="item.name=='bell' && item.show" id="tooltip-bell">
                                <b-iconstack class="navicon">
                                    <b-icon stacked class="navicon" :icon="item.name"></b-icon>
                                    <b-icon stacked shift-v="6.5" shift-h="6.5" variant="danger" class="navicon" icon="dot"></b-icon>
                                </b-iconstack>                         
                            </span>
                            <span class="fullscreenbtn darkenTextOnHover" v-b-tooltip.hover.v-light="{ customClass: 'tooltip-header border p-0 rounded' }" title="Tela Inteira" v-on:click="openFullscreen()" v-else-if="item.name=='arrows-fullscreen' && item.show">
                                <b-icon class="navicon" :icon="item.name" ></b-icon>
                            </span>  
                            <span v-else-if="item.show">
                                <b-icon class="navicon" :icon="item.name"></b-icon>
                            </span>      
                        </div>
                        <b-nav-text class="message pe-none mx-2" v-if="user.nickname || user.name">
                            <span class="text-secondary">{{message}}</span>
                            <span class="text-capitalize text-purple fw-semibold">{{user.nickname || user.name}}</span>
                        </b-nav-text>
                        <span class="d-flex align-items-center darkenTextOnHover ms-2" role="button" @click.prevent="logout">
                            <b-icon icon="power"></b-icon>
                            <div class="ms-1">
                                Sair
                            </div>
                        </span>
                    </span>
                    <!-- <b-nav-item class="navitems" v-if="user.role!='admin'">
                        <div class="useravatar">
                            <img class="usericon" :src="user.photoURL" v-if="user.photoURL">
                            <img class="usericon" src="https://firebasestorage.googleapis.com/v0/b/notifiqueai-app.appspot.com/o/assets%2Fdefault.jpg?alt=media&token=73be2b6f-a8a3-4407-b079-ab3918d37d8c" v-else>
                            <div class="avatardrop">
                                <div class="avatardroptriangle"></div>
                                <div class="avatardroplinkdiv" @click.prevent="selectUser" v-b-modal.modal-profile>
                                    <b-icon class="avatardropicon" icon="person"></b-icon>
                                    <a class="avatardroplink">
                                        Meu Perfil
                                    </a>
                                </div>
                                <div class="avatardroplinkdiv" @click.prevent="logout">
                                    <b-icon class="avatardropicon" icon="power"></b-icon>
                                    <a class="avatardroplink">
                                        Sair
                                    </a>
                                </div>
                            </div>
                        </div>
                    </b-nav-item> -->
                </b-navbar-nav>
            </b-navbar>
        </header>
        <b-tooltip custom-class="tooltipbell" target="tooltip-bell" triggers="hover" variant="light" v-if="icons.find(item => item.name=='bell')">
            <div class="dropbellheader">
                <span class="dropbelltitle">Notificações</span>
                <span class="dropbellnotificationsnumber">0 Novas</span>
            </div>
            <div class="dropbellbody">
                <b-row class="dropbellnotificationsrow" v-for="item in notifications" :key="item.id">
                    <b-col class="dropbellnotificationsiconcol" cols="2">
                        <b-icon class="dropbellnotificationsicon" :icon="item.icon" :style="item.color"></b-icon>
                    </b-col>
                    <b-col>
                        <div class="dropbellnotificationstitle">{{item.title}}</div>
                        <div class="dropbellnotificationsdesc">{{item.description}}...</div>
                    </b-col>
                </b-row>
            </div>
            <b-button class="dropbellallnotificationsbtn">Ver Todas as Notificações</b-button>
        </b-tooltip>
        <b-modal id="modal-profile" ref="modal-profile" size="lg" header-class="attheadermodalheader px-3" body-class="py-0" hide-footer v-if="arrayAux">
            <template #modal-title>
                <div class="d-flex align-items-center">
                    <b-icon icon="people"></b-icon>
                    <span class="px-2">Meu Perfil</span> 
                </div>
            </template>
            <form @submit.prevent="editProfile">
                <b-row class="">
                    <b-col class="usercol1">
                        <b-row class="userinforow pt-3 pb-2 mx-1">
                            <b-col cols="2" class="clientstatscol clientstatscol1">
                                <span class="profileuseravatar position-relative" >
                                    <b-form-file
                                    class="inputavatar"
                                    @input="setChannelAvatar"
                                    >
                                        <template #placeholder>
                                            <img class="clientstatsavatar rounded-circle" :src="arrayAux.photoURL" v-if="arrayAux.photoURL">
                                            <img class="clientstatsavatar rounded-circle" src="https://firebasestorage.googleapis.com/v0/b/notifiqueai-app.appspot.com/o/assets%2Fdefault.jpg?alt=media&token=73be2b6f-a8a3-4407-b079-ab3918d37d8c" v-else>
                                            <div class="usersemicircle">
                                                <b-icon icon="image-fill"></b-icon>
                                                <div>Atualizar</div> 
                                            </div>
                                        </template>
                                        <template #file-name>
                                            <img class="clientstatsavatar rounded-circle" :src="arrayAux.photoURL" v-if="arrayAux.photoURL">
                                            <div class="usersemicircle">
                                                <b-icon icon="image-fill"></b-icon>
                                                <div>Atualizar</div> 
                                            </div>
                                        </template>
                                    </b-form-file>
                                </span>
                            </b-col>
                            <b-col class="clientstatscol">
                                <div>
                                    <h5 class="profileusername m-0">
                                            {{arrayAux.name}}
                                    </h5>
                                    <div class="profileuserjob" v-if="arrayAux.company">{{arrayAux.job}} {{arrayAux.company}}.</div>
                                    <b-rating class="p-0" v-model="arrayAux.stars" no-border inline variant="warning"></b-rating>
                                </div>
                            </b-col>
                        </b-row>
                        <b-form-group class="my-2 mt-3" label="Nome Completo:">
                            <b-form-input v-model="arrayAux.name" required></b-form-input>
                        </b-form-group>
                        <b-row class="pt-1">
                            <b-col class="mt-2">
                                <b-form-group label="CPF:">
                                    <b-form-input v-model="arrayAux.cpf" v-maska='["###.###.###-##", "##.###.###/####-##"]'></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2">
                                <b-form-group label="Telefone:">
                                    <b-form-input v-model="arrayAux.mobile"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="py-3">
                        <b-form-group label="Email:">
                            <b-form-input v-model="arrayAux.email" required></b-form-input>
                        </b-form-group>
                        <b-form-group class="my-2" label-class="py-1" label="Alerta Sonoro:">
                            <b-form-checkbox v-model="arrayAux.soundAlert" class="col-form-label m-0 p-0"><div class="px-2">Sim</div></b-form-checkbox>
                        </b-form-group>
                        <b-row>
                            <b-col>
                                <b-form-group label="Mudar Apelido:">
                                    <b-form-input v-model="arrayAux.nickname"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Mudar Senha:">
                                    <b-input-group>
                                        <b-form-input v-model="arrayAux.password" type="password"></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div class="profilebtns mt-3">
                            <b-button type="submit" class="savebtn">Salvar</b-button>
                            <b-button class="cancelbtn" @click="$bvModal.hide('modal-profile')">Cancelar</b-button>
                        </div>
                    </b-col>
                </b-row>
            </form>
        </b-modal>
    </b-row>
</template>

<script>
import Sidebar from './sidebar.vue'
import moment from 'moment';
import 'moment/locale/pt-br';
import {logout} from '../services/userService.js'
export default {
    props:[
        'fullscreen', 'sidebarWidth', 'user', 'mediaURL', 'socket'
    ],
    created: function() {
        this.setTime();
        this.onCloseModal()
    },
    components: {
        Sidebar,
    },
    methods: {
        selectUser() {
            this.arrayAux = Object.assign({}, this.user);
        },
        setTime() {
            setInterval(() => {
                const currentMoment = moment().locale('pt-br');
                if (this.data) {
                    this.data.hora = currentMoment.format('LTS');
                    this.data.dia = currentMoment.format('MMM DD');
                }
            });
        },
        openFullscreen() {
            if (!document.fullscreenElement &&    // alternative standard method
                !document.mozFullScreenElement && !document.webkitFullscreenElement) {
                if (this.elem.requestFullscreen) {
                    this.elem.requestFullscreen();
                } else if (this.elem.webkitRequestFullscreen) { /* Safari */
                    this.elem.webkitRequestFullscreen();
                } else if (this.elem.msRequestFullscreen) { /* IE11 */
                    this.elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                }
            }
            this.$emit('fullscreen')
        },
        editProfile() {
            Object.assign(this.user, this.arrayAux);
            this.$emit('msg', { text: 'Usuário editado com sucesso!', success: true });
            this.$refs['modal-profile'].hide();
        },
        setChannelAvatar(e) {
            const reader = new FileReader();
            reader.readAsDataURL(e)
            reader.onload = ()=>{
                const dataURL = reader.result;
                this.arrayAux.photoURL = dataURL.replace('base64','charset=utf-8;base64');
            }
        },
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.arrayAux = null;
            })
        },
        logout() {
            logout()
        },
        verifyPhotoURL(url) {
            if(url && !url.includes('http')) {
                return `${this.mediaURL}${url}`;
            }
            return url;
        },
    },
    data() {
        return {
            arrayAux:null,
            elem: document.documentElement,
            icons: [
                // {
                //     name: 'bell',
                //     show: this.user ? ['channel','channel_manager'].includes(this.user.role) : false
                // },
                {
                    name: 'arrows-fullscreen',
                    show: true
                },
                // {
                //     name: 'people',
                // },
            ],
            // user: {
            //     photoURL: '',
            //     name: 'Bia Mitchell Hope',
            //     nickname: 'Bia',
            //     mobile: '+5500123456789',
            //     cpf: '00000000000',
            //     job: 'Operadora',
            //     company: 'Gobot',
            //     email: 'biamitchell@gobot.com.br',
            //     stars: '5',
            // },
            message: "Olá, ",
            notifications: [
                {
                    icon: 'exclamation-triangle',
                    color: 'color:hsl(38deg 100% 73%)',
                    title: 'Oscilação da Plataforma',
                    description: 'Atenção, todos os clientes',
                },
                {
                    icon: 'exclamation-triangle',
                    color: 'color:hsl(277deg 22% 75%)',
                    title: 'Chamada Ativa',
                    description: 'No dia 10/05 estará sendo realizada',
                },
                {
                    icon: 'exclamation-triangle',
                    color: 'color:hsl(278deg 22% 75%)',
                    title: 'Instabilidade da Plataforma',
                    description: 'Nesta semana, nossos clientes',
                },
                {
                    icon: 'exclamation-triangle',
                    color: 'color:hsl(10deg 77% 74%)',
                    title: 'Manutenção',
                    description: 'Neste Sabado e Domingo',
                },
            ],
            data:{
                dia: 'Jun 22',
                hora: '12:30:00',
            },
        }
    },
}
</script>

<style>
    .tooltip-header .tooltip-inner{
        color: hsl(240deg 6% 42%) !important;
    }
    .tooltip-header{
        margin-top: .5em !important;
    }
    .tooltipbell .tooltip-inner {
        max-width: 350px !important;
        text-align: left;
        border:1px solid  #ddd;
        padding: 0;
    }
    .savebtn{
        background-color: hsl(142deg 38% 59%) !important;
        border-color: hsl(142deg 38% 59%)!important;
        color: #fff!important;
        padding: 5px 25px!important;
        margin-right: 5px;
    }
    .cancelbtn{
        background-color: #fff!important;
        border-color: #eee!important;
        color: #777!important;
        padding: 5px 25px!important;
    }
    .inputavatar>input{
        display: none;
    }
</style>

<style scoped>
    #headerrow{
        position: sticky;
        right: 0;
        top: 0;
        background-color: hsl(248deg 50% 97%);
        z-index: 10;
        box-shadow: 3px 0px 5px #ccc;
    }
    .headerfullscreen{
        padding: 0;
        padding-right: 10px;
        width: 100% !important;
    }
    .navitemscenter{
        display: flex;
        align-items: center;
    }
    .navitems{
        margin: 0 10px;
        cursor: pointer;
    }
    .navicon{
        font-size: 1.5em !important;
    }
    .usericon{
        height: 40px;
        width: 40px;
        border: none;
        border-radius: 50%;
    }
    .avatardrop{
        position: absolute;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 10px 0;
        margin-top: 12px;
        right: 0px;
        font-size: 0.8em;
        display: none;
    }
    .avatardroptriangle{
        position: absolute;
        height: 13px;
        width: 13px;
        transform: rotate(45deg);
        border: .5px solid #ccc;
        border-bottom: none;
        border-right: none;
        background-color: #fff;
        right: 30px;
        top: -7px;
    }
    .avatardroplink{
        color: hsl(240deg 6% 42%) !important;
    }
    .avatardroplinkdiv{
        padding: 5px 25px;
    }
    .avatardroplinkdiv:hover{
        background-color: #eee;
    }
    .avatardropicon{
        color: hsl(240deg 6% 42%);
        font-size: 2em;
    }
    .nav-item:hover .avatardrop{
        display: block;
    }
    .dropbell{
        display: none;
    }
    .navitems:hover .dropbell{
        display: block;
    }
    .tooltipbell{
        font-size: 1.2em;
    }
    .dropbellheader{
        border-bottom: 0.5px solid #ddd;
        padding: 20px;
    }
    .dropbelltitle{
        color: hsl(240deg 6% 42%) !important;
        font-size: 1.1em;
        font-weight: 500;
        float: left;
        display: contents;
    }
    .dropbellnotificationsnumber{
        background-color: hsl(9deg 78% 67%);
        padding: 4px 20px;
        border-radius: 25px;
        color: #fff;
        float: right;
        font-size: 0.8em;
        cursor: pointer;
    }
    .dropbellbody{
        clear: both;
    }
    .dropbellnotificationsiconcol{
        padding-left: 10px;
    }
    .dropbellnotificationsrow{
        border-bottom: 0.5px dotted #ddd;
        padding: 20px;
        margin: 0px;
    }
    .dropbellnotificationsicon{
        font-size: 1.7em !important;
        margin-top: 10px;
    }
    .dropbellnotificationstitle{
        color: hsl(244deg 6% 43%);
        font-size: 0.9em;
        font-weight: 500;
    }
    .dropbellnotificationsdesc{
        color: hsl(240deg 9% 60%);
        font-size: 0.75em;
    }
    .dropbellallnotificationsbtn{
        display: flex;
        margin: 20px auto;
        background-color: #fff;
        color: hsl(244deg 6% 43%);
        border-radius: 25px;
        border: 0.5px solid #ddd;
        padding: 5px 20px;
        font-size: 0.8em;
    }
    .data{
        color: hsl(249deg 19% 61%);
        border-radius: 25px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        padding: 0 20px;
        padding-right: 45px;
        display: inline-flex;
        align-items: center;
    }
    .fullscreenleftdiv,.smallerscreendiv{
        padding-left: 1em;
        display: flex;
        align-items: center;
    }
    .smallerscreendiv,.smallerscreenlogo{
        display: none;
    }
    .fullscreenlogo,.smallerscreenlogo{
        height: 50px;
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.6));
    }
    .fullscreenlogo:hover{
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.8));
    }
    .clientstatscol{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .clientstatscol1{
        align-items: center;
    }
    .clientstatsavatar{
        height: 70px;
        width: 70px;
    }
    .clientstatsname{
        font-size: 1.2em;
    }
    .b-rating{
        font-size: 0.8em;
        background-color: transparent;
        margin-top: 5px;
    }
    .profileusername{
        color: hsl(249deg 7% 35%);
    }
    .profileuserjob{
        color: hsl(247deg 13% 60%);
        font-size: 0.9em;
    }
    .userinforow{
        border-bottom: 1px solid #eee;
    }
    .usercol1{
        border-right: 1px solid #eee;
    }
    .profilebtns{
        padding-top: 2px;
    }
    .usersemicircle{
        position: absolute;
        bottom: -15%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 35px;
        width: 70px;
        border-radius: 0 0 150px 150px ;
        background-color: #333;
        color: #fff;
        opacity: 0.75;
        flex-direction: column;
        align-items: center;
        font-size: 0.7em;
        padding: 3px;
        display: none;
    }
    .profileuseravatar:hover .usersemicircle{
        display: flex;
    }
    @media (max-width: 1023px) {
        /* .header{
            padding: 0;
        } */
    }
    @media(max-width: 768px) {
        .fullscreenbtn{
            display: none;
        }
        .navitems{
            margin: 0;
        }
        .navitems .b-icon{
            margin: 0 10px;
        }
    }
    @media(max-width: 500px) {
        .fullscreenleftdiv .data{
            display: none;
        }
    }
</style>