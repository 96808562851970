<template>
    <div>
        <b-popover :target="`popover-submenu${depth}${menu.text}`"
            :ref="`popover-submenu${depth}${menu.text}`"
            triggers="hover" 
            custom-class="popoverSidebarSubmenu" 
            boundary="document"
            v-if="menu.submenus?.length"
        >
            <div v-for="submenu in menu.submenus" :key="submenu.id">
                <div @click="goTo(submenu)" v-if="submenu.show">
                    <div :id="`popover-submenu${depth + 1}${submenu.text}`" class="submenuitem text-purple text-start px-3 py-1 d-flex align-items-center" role="button">
                        <b-icon class="me-2" icon="circle-fill" font-scale="0.25"></b-icon> 
                        {{ submenu.text }}
                        <sidebar-submenu :menu="submenu" :depth="depth + 1" v-if="submenu.submenus" />
                    </div>
                </div>
            </div>
        </b-popover>
    </div>
</template>

<script>
    export default {
        name: "sidebar-submenu",
        props: [
            "menu", "depth"
        ],
        methods: {
            goTo(item) {
                if(item.url && !item.submenus) {
                    if(this.$router.history.current.path != item.url) {
                        this.$router.replace({path:item.url})
                        const content = document.querySelector('#content')
                        
                        if (content)
                            content.scrollTo(0,0)
                    }              
                }            
            },
            onHide($el) {
                // console.log("$el",$el,this.depth,this.menu)
                if(this.menu.submenus?.length)
                    $el.preventDefault()
            }
        }
    }
</script>

<style scoped>
    .submenuitem:hover{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
    .sidebarfullscreen .submenuitem{
        padding-left: 0.75em;
    }
</style>