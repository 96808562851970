<template>
    <div class="subheader d-flex justify-content-between align-items-center fs-5 mb-3">
        <div class="d-flex align-items-center">
            <h4 class="m-0">
                {{subH.title}}
            </h4>
            <span class="pageinfo"> 
                <span class="barravertical"></span> 
                <b-icon class="iconinfo" :icon="subH.icon"></b-icon>
                <span class="titleinfo"> - {{subH.subtitle}}</span>
            </span>
        </div>
        <span class="bg-white px-4 py-1 rounded-pill text-purple smaller-text">Hoje: <span class="text-capitalize mx-1">{{data.dia}}</span> • {{data.hora}}</span>
    </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/pt-br';
export default {
    props:['subH'],
    components: {

    },
    created: function(){
        this.setTime();
    },
    methods: {
        setTime() {
            setInterval(() => {
                const now = moment().locale('pt-br');
                this.data.hora = now.format('LTS');
                this.data.dia = now.format('MMM DD');
            }, 1000);
        }
    },
    data(){
        return {
            data:{
                dia: 'Jun 22',
                hora: '12:30:00',
            },
        }
    },
}
</script>

<style scoped>
    .barravertical{
        border-left: 1px solid;
        margin: 0 1vw;
        font-size: 1.2em;
    }
    .pageinfo, .data{
        color: #aaa;
    }
    .iconinfo{
       font-size: 1em;
       margin-bottom: 2px;
    }
    .titleinfo{
        font-size: 0.6em;
        position: relative;
        bottom: 5px;
    }
</style>