import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import "./utils/filters"
import Maska from 'maska'
import ViaCep from 'vue-viacep'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueCryptojs from 'vue-cryptojs'
import './registerServiceWorker'

Vue.use(VueCryptojs)
Vue.use(ViaCep);
Vue.use(Maska)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBaxsRt8xf4B-blm2c36sZgky9Jp6HaHZo',
    libraries: 'places',
  },
})

Vue.component(VueQrcode.name, VueQrcode);
Vue.component("v-select", vSelect);

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: createElement => createElement('b-icon', {
      props: {
        icon: 'chevron-down',
      },
      class: 'select-caret'
    }),
  },
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
