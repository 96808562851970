// vue.config.js

/**
 * @type {import('@vue/cli-service').ProjectOptions}
 */
 module.exports = {
    // options...
    // publicPath: process.env.NODE_ENV === 'production' ? '/gotalk/' : '/'
    publicPath: '/',
    pwa: {
      name: 'gotalk',
      themeColor: '#4DBA87',
      msTileColor: '#000000',
      appleMobileWebAppCapable: 'yes',
      appleMobileWebAppStatusBarStyle: 'black',
  
      // configure the workbox plugin
      workboxPluginMode: 'InjectManifest',
      workboxOptions: {
        // swSrc is required in InjectManifest mode.
        swSrc: 'src/main.js',
        // ...other Workbox options...
      },
      manifestOptions: {
        name: 'Gotalk',
        icons: [
          {"src":"./images/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},
          {"src":"./images/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},
          {"src":"./images/android-chrome-maskable-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},
          {"src":"./images/android-chrome-maskable-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}
        ]
      },
      iconPaths:{
        faviconSVG: 'images/favicon.png',
        favicon32: 'images/favicon.png',
        favicon16: 'images/favicon.png',
        appleTouchIcon: 'images/favicon.png',
        maskIcon: 'images/favicon.png',
        msTileImage: 'images/favicon.png'
      }
    }
  }