const request = require('request')
const UserService = require('./userService.js')
let headers = { }
const axios = require('axios')
let baseURL = window.location.host.includes('localhost') ? process.env.VUE_APP_BASEURL : `${window.location.protocol}//${window.location.host}`

async function doRequest(url, method, data = undefined, headersParams = null) {
    try {
        headers.authorization = UserService.getToken()
        
        if (headersParams) {
            headers = { ...headers, ...headersParams }
        }

        // const resp = await axios[method](url, data, { headers }) // headers is not sent
        const resp = await axios({ method, url, data, headers })

        if (resp?.data) {
            if (typeof resp.data === 'string' && !resp.data.includes('{'))
                return { message: resp.data }
            try {
                const body = JSON.parse(resp.data)
                resp.data = body
            } catch {
                console.log()
            }

            if (
                resp.data?.result?.statusCode == 401 ||
                resp.data?.statusCode == 401 ||
                resp.statusCode == 401
            ) {
                UserService.logout()
            }

            // console.log('resp.data',resp.data)

            return { ...resp.data }
        } else {
            return { statusCode: 400, error: "unknown" }
        }
    } catch (error) {
        return { statusCode: error.response?.status || 400, error: error.response?.statusText || error.response?.data?.error || error.response?.data || error, erroMensagem: error.response?.data?.erroMensagem }
    }
}

async function authenticate(form) {
    return await doRequest(`${baseURL}:5965/user/login`,'post',form)
}

async function getOperatorToken(channelToken, operatorId) {
    return await doRequest(`${baseURL}:5965/user/operator-token/operatorId/${operatorId}`,'get', undefined, { authorization: channelToken })
}

async function getOperatorTokenAzure(state) {
    return await doRequest(`${baseURL}:5982/integration/eliane/authentication-azure/state/${state}`,'get', null, { authorization: process.env.VUE_APP_DEV_TOKEN })
}

async function createUser(user) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5965/user/register`, { headers, form: user },(error,resp) => {
                if(error) {
                    console.error('createUser',error)
                    resolve({ statusCode: 400, error })
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('createUser',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function createDepartment(department) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5966/department`, { headers, form: department },(error,resp) => {
                if(error) {
                    console.error('createDepartment',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('createDepartment',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function createSecChannel(channel) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5962/channel/secondary`, { headers, form: channel },(error,resp) => {
                if(error) {
                    console.error('createSecChannel',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('createSecChannel',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function createMenu(menu) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5961/menu`, { headers, form: menu },(error,resp) => {
                if(error) {
                    console.error('createMenu',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('createMenu',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function createContact(contact) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5968/contact`, { headers, form: contact },(error,resp) => {
                if(error) {
                    console.error('createContact',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('createContact',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function createTabulation(tabulation,channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5962/channel/tabulations/${channelId}`, { headers, form: tabulation },(error,resp) => {
                if(error) {
                    console.error('createTabulation',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('createTabulation',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function createTag(form,channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5962/channel/tags/${channelId}`, { headers, form },(error,resp) => {
                if(error) {
                    console.error('createTag',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('createTag',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function createQuickAnswer(channelId, form) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5967/message/quick-answers/${channelId}`, { headers, form },(error,resp) => {
                if(error) {
                    console.error('createQuickAnswer',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('createQuickAnswer',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateUser(userObj) {
    let objAux = (({ login, password }) => ({ login, password }))(userObj);
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5965/user/${userObj.user}`, { headers, form: objAux },(error,resp) => {
                if(error) {
                    console.error('updateUser',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateUser',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateChannel(channel) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5962/channel/${channel._id}`, { headers, form: channel },(error,resp) => {
                if(error) {
                    console.error('updateChannel',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateChannel',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateOperator(operator) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5963/operator/${operator._id}`, { headers, form: operator },(error,resp) => {
                if(error) {
                    console.error('updateOperator',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateOperator',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateDepartment(department) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5966/department/${department._id}`, { headers, form: { name: department.name, channelId: department.channelId } },(error,resp) => {
                if(error) {
                    console.error('updateDepartment',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateDepartment',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateMenu(menu) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5961/menu/${menu.menus[0]._id}`, { headers, form: menu },(error,resp) => {
                if(error) {
                    console.error('updateMenu',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateMenu',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function addMenuOption(menuId, option) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5961/menu/add/option/menuId/${menuId}`, { headers, form: option },(error,resp) => {
                if(error) {
                    console.error('addMenuOption',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('addMenuOption',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateSections(sections,menuId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5961/menu/session/${menuId}`, { headers, form: {sections} },(error,resp) => {
                if(error) {
                    console.error('updateSections',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateSections',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateButtons(buttons,menuId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5961/menu/buttons/${menuId}`, { headers, form: { buttons } },(error,resp) => {
                if(error) {
                    console.error('updateButtons',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateButtons',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updatePresentation(presentation,menuId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5961/menu/presentation/${menuId}`, { headers, form: presentation },(error,resp) => {
                if(error) {
                    console.error('updateSections',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateSections',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateContact(contact) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5968/contact/${contact._id}`, { headers, form: contact },(error,resp) => {
                if(error) {
                    console.error('updateContact',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateContact',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateContactName(contactNumber,form) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5968/contact/update/name/contactNumber/${contactNumber}`, { headers, form },(error,resp) => {
                if(error) {
                    console.error('updateContactName',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateContactName',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateContactCode(contactNumber,channelId, code) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.patch(`${baseURL}:5968/contact/update/contactCode/contactNumber/${contactNumber}/channelId/${channelId}`, { headers, form: { code } },(error,resp) => {
                if(error) {
                    console.error('updateContactCode',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateContactCode',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateContactTag(contactNumber,channelId, tag) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.patch(`${baseURL}:5968/contact/update/contactTag/contactNumber/${contactNumber}/channelId/${channelId}`, { headers, form: { tag } },(error,resp) => {
                if(error) {
                    console.error('updateContactTag',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateContactTag',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateContactEmail(contactNumber,channelId, email) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.patch(`${baseURL}:5968/contact/update/email/contactNumber/${contactNumber}/channelId/${channelId}`, { headers, form: { email } },(error,resp) => {
                if(error) {
                    console.error('updateContactEmail',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateContactEmail',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateContactResponsibleOperator(contactId, operatorId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.patch(`${baseURL}:5968/contact/update/responsible/operator/${contactId}`, { headers, form: { operatorId } },(error,resp) => {
                if(error) {
                    console.error('updateContactResponsibleOperator',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateContactResponsibleOperator',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateChannelConfig(channel) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5962/channel/config/${channel.channelId}`, { headers, form: channel },(error,resp) => {
                if(error) {
                    console.error('updateChannelConfig',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateChannelConfig',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateTabulation(tabulation,channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5962/channel/tabulations/${channelId}`, { headers, form: tabulation },(error,resp) => {
                if(error) {
                    console.error('updateTabulation',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateTabulation',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateManager(manager) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5962/channel/manager/${manager._id}`, { headers, form: manager },(error,resp) => {
                if(error) {
                    console.error('updateManager',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateManager',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateOperatorGroupAccess(groupId,channelId,operatorId,status) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.patch(`${baseURL}:5962/group/operators/viewgroup/groupId/${groupId}/channelId/${channelId}/operatorId/${operatorId}`, { headers, form: { status } },(error,resp) => {
                if(error) {
                    console.error('updateOperatorGroupAccess',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateOperatorGroupAccess',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateTag(tagId, form) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5962/channel/tags/${tagId}`, { headers, form },(error,resp) => {
                if(error) {
                    console.error('updateTag',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateTag',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateQuickAnswer(id, form) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.put(`${baseURL}:5967/message/quick-answers/${id}`, { headers, form },(error,resp) => {
                if(error) {
                    console.error('updateQuickAnswer',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('updateQuickAnswer',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getChannel(channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5962/channel/${channelId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getChannel',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getChannel',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getChannels(page=1) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5962/channel/page/${page}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getChannels',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getChannels',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getSecondaryChannels(mainChannelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5962/channel/secondary/channelId/${mainChannelId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getSecondaryChannels',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getSecondaryChannels',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getChannelsFilter(filters, page = 1) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5962/channel/filter/page/${page}`,{ headers, form: filters },(error,resp) => {
                if(error) {
                    console.error('getChannelsFilter',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getChannelsFilter',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getChannelsGotQr() {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5953/qr/got_qr`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getChannelsGotQr',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getChannelsGotQr',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function getChannelsGupshup() {
    return await doRequest(`${baseURL}:5962/channel/gupshup`, 'get')
}

async function loadMessages(form) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5967/message/load_messages`,{ headers, form },(error,resp) => {
                if(error) {
                    console.error('loadMessages',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('loadMessages',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function loadGroupMessages(form) {
    return await doRequest(`${baseURL}:5967/message/load_messages_group`, 'post', form)
}

async function loadGroupMessagesWeek(form) {
    return await doRequest(`${baseURL}:5967/message/load_messages_group/week`, 'post', form)
}

async function loadGroupPreviousMessages(form) {
    return await doRequest(`${baseURL}:5967/message/load_messages_group/previous`, 'post', form)
}

async function getMenus(channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5961/menu/channel/${channelId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getMenus',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getMenus',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function getMenuTemplates(channelId) {
    return await doRequest(`${baseURL}:5961/menu/templates/channelId/${channelId}`)
}

async function getMenuTemplatesPagination(channelId, page = 1) {
    return await doRequest(`${baseURL}:5961/menu/templates/channelId/${channelId}/page/${page}`)
}

async function getMenu(menuId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5961/menu/${menuId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getMenu',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getMenu',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getMenuOptions(menuId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5961/menu/options/menus/menuId/${menuId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getMenuOptions',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getMenuOptions',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getMenuFromTopmenu(topMenuId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5961/menu/topmenu/${topMenuId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getMenuFromTopmenu',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getMenuFromTopmenu',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getMenuRefs(optionId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5961/menu/ref/${optionId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getMenuRefs',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getMenuRefs',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getContacts(channelId,page=1) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5968/contact/channel/${channelId}/page/${page}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getContacts',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getContacts',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function getRetrieveContacts(channelId, operatorId, type, page) {
    return await doRequest(`${baseURL}:5968/contact/retrieve-contacts/${channelId}/operatorId/${operatorId}/type/${type}/page/${page}`, 'get')
}

async function getContactsFilter(channelId,filter,page = 1) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5968/contact/filter/channel/${channelId}/page/${page}`,{ headers, form: { filter } },(error,resp) => {
                if(error) {
                    console.error('getContactsFilter',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getContactsFilter',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function getContactsSearch(channelId, operatorId, search) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5968/contact/channel/${channelId}/operatorId/${operatorId}/search/${search}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getContactsSearch',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getContactsSearch',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function getAllContacts(channelId, filter) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5968/contact/export/channel/${channelId}`,{ headers, form: { filter } },(error,resp) => {
                if(error) {
                    console.error('getAllContacts',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getAllContacts',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function getContactsCountFromAllChannels() {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5968/contact/total/account`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getAllContacts',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getAllContacts',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function getContactsTotal(channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5962/channel/number-contacts/${channelId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getContactsTotal',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getContactsTotal',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function getContact(contactId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5968/contact/${contactId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getContact',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getContact',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function getBirthdayContacts(channelId,page=1) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5968/contact/birthdays-day/channelId/${channelId}/${page}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getBirthdayContacts',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getBirthdayContacts',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function getReportAttendance(data) {
    return await doRequest(`${baseURL}:5952/report/attendance`, 'post', data)
}

async function getReportAttendanceFull(data) {
    return await doRequest(`${baseURL}:5952/report/attendance/full`, 'post', data)
}

async function getReportSales(channelId, data) {
    return await doRequest(`${baseURL}:5952/report/sales/channelId/${channelId}`, 'post', data)
}

async function getReportAttendanceCount(data) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5952/report/attendance/count`,{ headers, form: data },(error,resp) => {
                if(error) {
                    console.error('getReportAttendanceCount',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getReportAttendanceCount',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function getReportTabulation(data) {
    return await doRequest(`${baseURL}:5952/report/attendance/tabulations`, 'post', data)
}

async function getReportTabulationFull(data) {
    return await doRequest(`${baseURL}:5952/report/attendance/tabulations/full`, 'post', data)
}

async function getReportTabulationCount(data) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5952/report/attendance/tabulations/count`,{ headers, form: data },(error,resp) => {
                if(error) {
                    console.error('getReportTabulationCount',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getReportTabulationCount',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function getFinishedAttendancesCount(data) {
    return await doRequest(`${baseURL}:5952/report/finished-attendances/count`, 'post', data)
}

async function getFinishedAttendancesPagination(data, page = 1) {
    return await doRequest(`${baseURL}:5952/report/view-finished-attendances/${page}`, 'post', data)
}

async function getReportAverage(data) {
    return await doRequest(`${baseURL}:5952/report/attendance/average`, 'post', data)
}

async function getReportAverageFull(data) {
    return await doRequest(`${baseURL}:5952/report/attendance/average/full`, 'post', data)
}

async function getReportAverageCount(data) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5952/report/attendance/average/count`,{ headers, form: data },(error,resp) => {
                if(error) {
                    console.error('getReportAverageCount',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getReportAverageCount',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function getReportCampaign(campaignId, filter = null) {
    return await doRequest(`${baseURL}:5952/report/campaign/campaignId/${campaignId}${filter ? `/${filter}` : ''}`, 'get')
}

async function getChannelConfig(channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5962/channel/config/${channelId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getChannelConfig',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getChannelConfig',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function getTabulations(channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5962/channel/tabulations/${channelId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getTabulations',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getTabulations',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function getDepartments(channelId,showGhosts = false) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5966/department/hideDeleted/channel/${channelId}${showGhosts ? '/true' : ''}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getDepartments',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getDepartments',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getAttendanceById(attendanceId) {
    return await doRequest(`${baseURL}:5950/attendance/attendanceId/${attendanceId}`, 'get')
}

async function getAttendances(operatorId) {
    return await doRequest(`${baseURL}:5950/attendance/operatorId/${operatorId}`, 'get')
}

async function getAttendancesPagination(operatorId, page = 1, itemsPerPage = 10) {
    return await doRequest(`${baseURL}:5950/attendance/operatorId/${operatorId}/page/${page}/perPage/${itemsPerPage}`, 'get')
}

async function getAttendancesPaginationByStatus(operatorId, page = 1, itemsPerPage = 10, status = null) {
    return await doRequest(`${baseURL}:5950/attendance/operatorId/${operatorId}/page/${page}/perPage/${itemsPerPage}`, 'post', { filter: { status } })
}

async function getAttendancesCount(channelId,date) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5950/attendance/channelId/${channelId}/count/${date}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getAttendancesCount',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getAttendancesCount',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getContactsWithoutAttendance(channelId, data) {
    return await doRequest(`${baseURL}:5968/contact/without-attendance/channelId/${channelId}`, 'post', data)
}

async function getOldAttendances(channelId, form) {
    return await doRequest(`${baseURL}:5950/attendance/search/old/channelId/${channelId}`, 'post', form)
}

async function searchAttendances(channelId, form) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5950/attendance/search/filter/channelId/${channelId}`,{ headers, form },(error,resp) => {
                if(error) {
                    console.error('searchAttendances',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('searchAttendances',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getContactHistory(channelId,clientNumber) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5950/attendance/history/channelId/${channelId}/clientNumber/${clientNumber}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getContactHistory',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getContactHistory',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getProtocolMessages(attendanceId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5967/message/load/protocol/attendanceId/${attendanceId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getProtocolMessages',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getProtocolMessages',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getAttendanceMessages(channelId, attendanceId) {
    return await doRequest(`${baseURL}:5950/attendance/history/channelId/${channelId}/attendanceId/${attendanceId}`,'get')
}

async function getOperator(operatorId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5963/operator/${operatorId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getOperator',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getOperator',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getOperators(channelId, showGhosts = false) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5963/operator/hideDeleted/channel/${channelId}${showGhosts ? '/true' : ''}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getOperators',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getOperators',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getOperatorsCountFromAllChannels() {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5963/operator/total/count`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getOperatorsCountFromAllChannels',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getOperatorsCountFromAllChannels',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getOnlineOperators(channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5963/operator/online/${channelId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getOnlineOperators',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getOnlineOperators',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getOperatorData(operatorData) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5963/operator/${operatorData}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getOperatorData',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getOperatorData',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getCommunicationsIndex(channelId,filter) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5952/report/attendance/filter/channelId/${channelId}`,{ headers, form: { filter } },(error,resp) => {
                if(error) {
                    console.error('getCommunicationsIndex',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getCommunicationsIndex',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getTME(channelId,filter) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5950/attendance/average/time/waiting/channelId/${channelId}`,{ headers, form: { filter } },(error,resp) => {
                if(error) {
                    console.error('getTME',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getTME',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getTMA(channelId,filter) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5950/attendance/average/time/attendance/channelId/${channelId}`,{ headers, form: { filter } },(error,resp) => {
                if(error) {
                    console.error('getTMA',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getTMA',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getTopMenus(channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5961/menu/top/accessed/channelId/${channelId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getTopMenus',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getTopMenus',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getManagers(channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5962/channel/manager/channel/${channelId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getManagers',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getManagers',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getManager(managerId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5962/channel/manager/${managerId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getManager',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getManager',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getApiServers() {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5953/qr/server/config`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getApiServers',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getApiServers',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getAttendancesFollowPagination(channelId, page, form) {
    return await doRequest(`${baseURL}:5950/attendance/follow/channelId/${channelId}/page/${page}`, 'post', form)
}

async function getAttendancesFollowCount(channelId, form) {
    return await doRequest(`${baseURL}:5950/attendance/follow/count/channelId/${channelId}`, 'post', form)
}

async function getAttendancesFollow(channelId, form) {
    return await doRequest(`${baseURL}:5950/attendance/follow/channelId/${channelId}`, 'post', form)
}

async function getMessageTemplates(channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5967/message/templates/channelId/${channelId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getMessageTemplates',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getMessageTemplates',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getGroups(channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5967/groups/channelId/${channelId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getGroups',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getGroups',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getOperatorsGroupAccess(channelId,groupId,status) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5962/group/operators/channelId/${channelId}/groupId/${groupId}/${status}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getOperatorsGroupAccess',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getOperatorsGroupAccess',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getGroupQuantity(channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5962/group/quantity/channelId/${channelId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getGroupQuantity',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getGroupQuantity',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getOperatorGroupsQuantity(operatorId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5962/group/quantity/operatorId/${operatorId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getOperatorGroupsQuantity',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getOperatorGroupsQuantity',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function updateGroupField(channelId, groupId, form) {
    return await doRequest(`${baseURL}:5962/group/channelId/${channelId}/groupId/${groupId}`, 'patch', form)
}

async function updateCatalogCategory(channelId, category) {
    return await doRequest(`${baseURL}:5969/catalog/category/channelId/${channelId}/categoryId/${category._id}`, 'put',  category)
}

async function GetOrderById(orderId) {
    return await doRequest(`${baseURL}:5969/order/orderId/${orderId}`, 'get')
}

async function getAttendanceQuantity(operatorId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5963/operator/total/attendances/count/operatorId/${operatorId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getAttendanceQuantity',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getAttendanceQuantity',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getTags(channelId,page) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5962/channel/tags/${channelId}/page/${page}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getTags',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getTags',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getAllTags(channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5962/channel/tags/${channelId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getAllTags',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getAllTags',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getQuickAnswers(channelId, filters) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5967/message/quick-answers/channelId/${channelId}`,{ headers, form: filters },(error,resp) => {
                if(error) {
                    console.error('getQuickAnswers',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getQuickAnswers',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getQuickAnswersByDepartments(channelId, form) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5967/message/quick-answers/departments/${channelId}`,{ headers, form },(error,resp) => {
                if(error) {
                    console.error('getQuickAnswersByDepartments',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getQuickAnswersByDepartments',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getAttendanceLastMessage(attendanceId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5967/message/last_message/${attendanceId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getAttendanceLastMessage',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getAttendanceLastMessage',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getAttendanceUnreadCount(attendanceId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5967/message/attendance_unread_count/${attendanceId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('getAttendanceUnreadCount',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getAttendanceUnreadCount',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getOperatorUnreadCount(operatorId) {
    return await doRequest(`${baseURL}:5967/message/operator_unread_count/${operatorId}`, 'get')
}

async function getActiveCommunicationTimeLeft(channelId, departmentId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5967/message/active_communication_time_left`,{ headers, form: { channelId, departmentId } },(error,resp) => {
                if(error) {
                    console.error('getActiveCommunicationTimeLeft',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getActiveCommunicationTimeLeft',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function getCatalogCategories(channelId) {
    return await doRequest(`${baseURL}:5969/catalog/categories/channelId/${channelId}`,'get')
}

async function getCatalogCategoryById(channelId, categoryId) {
    return await doRequest(`${baseURL}:5969/catalog/category/channelId/${channelId}/categoryId/${categoryId}`,'get')
}

async function getCatalogProducts(channelId) {
    return await doRequest(`${baseURL}:5969/product/channelId/${channelId}`,'get')
}

async function getCatalogProductsByKeyword(channelId, keyword, hide = false) {
    return await doRequest(`${baseURL}:5969/product/keyword/channelId/${channelId}`,'post',{ keyword, hide })
}

async function getCatalogProductsByCategoryLowestPrice(channelId, categoryId) {
    return await doRequest(`${baseURL}:5969/product/lowest-price-hide/channelId/${channelId}/categoryId/${categoryId}`,'get')
}

async function getCatalogProductsPagination(channelId, categoryId, filters = {}, page = 1) {
    return await doRequest(`${baseURL}:5969/product/channelId/${channelId}/categoryId/${categoryId}/page/${page}`,'post', { filters })
}

async function getCatalogProductByProductId(channelId, productId) {
    return await doRequest(`${baseURL}:5969/product/skuId/${productId}/channelId/${channelId}`,'get')
}

async function getAllProductsCount(channelId) {
    return await doRequest(`${baseURL}:5969/product/count/channelId/${channelId}`,'get')
}

async function getReportNewContacts(channelId, filters, startDate = null, endDate = null) {
    return await doRequest(`${baseURL}:5968/contact/new_contacts/channelId/${channelId}`,'post',{ filters, startDate, endDate })
}

async function getReportSurveyRatingsData(filters) {
    return await doRequest(`${baseURL}:5952/report/survey/ratings/count`,'post', { filters })
}

async function getMessagesTotalSentReceived(channelId, filters) {
    return await doRequest(`${baseURL}:5967/message/total/sent-received/channelId/${channelId}`,'post', { filters })
}

async function getSurveyTopOperator(filters) {
    return await doRequest(`${baseURL}:5952/report/survey/top/operator`,'post', { filters })
}

async function getSurveyTopOperators(filters, page) {
    return await doRequest(`${baseURL}:5952/report/survey/top/operators/${page}`,'post', { filters })
}

async function getChannelHasSecondaryChannels(mainChannelId) {
    return await doRequest(`${baseURL}:5962/channel/verify-secondary-channels/mainChannelId/${mainChannelId}`,'get')
}

async function getServersConfig() {
    return await doRequest(`${baseURL}:5962/server`,'get', null, null)
}

async function getAccessLog(filters, page = 1) {
    return await doRequest(`${baseURL}:5952/report/access-log/${page}`,'post', filters)
}

async function getGupshupTraffic(filters, page = 1, perPage = 10) {
    return await doRequest(`${baseURL}:5952/report/gupshup/partner/channels/traffic/page/${page}/perPage/${perPage}`,'post', filters)
}

async function getStates() {
    const result = await new Promise((resolve) => {
        try {
            request.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome`,(error,resp) => {
                if(error) {
                    console.error('getStates',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()

                    resolve(resp.body)
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getStates',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function deleteChannel(channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.delete(`${baseURL}:5962/channel/${channelId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('deleteChannel',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('deleteChannel',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function deleteOperator(operatorId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.delete(`${baseURL}:5963/operator/${operatorId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('deleteOperator',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('deleteOperator',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function deleteDepartment(departmentId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.delete(`${baseURL}:5966/department/${departmentId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('deleteDepartment',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('deleteDepartment',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function deleteMenu(menuId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.delete(`${baseURL}:5961/menu/${menuId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('deleteMenu',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('deleteMenu',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function deleteContact(contactId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.delete(`${baseURL}:5968/contact/${contactId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('deleteContact',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('deleteContact',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function deleteTabulation(tabulation,channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.delete(`${baseURL}:5962/channel/tabulations/${channelId}`,{ headers, form: tabulation },(error,resp) => {
                if(error) {
                    console.error('deleteTabulation',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('deleteTabulation',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function deleteManager(managerId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.delete(`${baseURL}:5962/channel/manager/${managerId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('deleteManager',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('deleteManager',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function deleteTag(tagId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.delete(`${baseURL}:5962/channel/tags/${tagId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('deleteTag',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('deleteTag',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function deleteQuickAnswer(tagId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.delete(`${baseURL}:5967/message/quick-answers/${tagId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('deleteQuickAnswer',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('deleteQuickAnswer',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function retrieveAttendance(channelId,form) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5950/attendance/retrieve/channelId/${channelId}`,{ headers, form },(error,resp) => {
                if(error) {
                    console.error('retrieveAttendance',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('retrieveAttendance',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function changeOperatorStatus(operatorId,status) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.patch(`${baseURL}:5963/operator/status/${operatorId}`,{ headers, form: { status } },(error,resp) => {
                if(error) {
                    console.error('getOperatorData',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('getOperatorData',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function generateAuthToken(channelId) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.get(`${baseURL}:5965/user/authentication-channel/${channelId}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('generateAuthToken',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('generateAuthToken',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function blockChannel(channelId,bool) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.patch(`${baseURL}:5962/channel/${channelId}/blocked/${bool}`,{ headers },(error,resp) => {
                if(error) {
                    console.error('blockChannel',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('blockChannel',error)
            resolve({ statusCode: 400, error }) 
        }
    })
    return result
}

async function upload(formData,headers) {
    try {
        headers.authorization = UserService.getToken()
        const resp = await axios.post(`${baseURL}:5951/upload`, formData, { headers })
        if(resp.data) {
            try {
                const body = JSON.parse(resp.data)
                resp.data = body 
            } catch {console.log()}
            return { statusCode: 200, ...resp.data }
        }
    } catch (error) {
        console.error('upload',error)
        return { statusCode: 400, error }
    }
    return { statusCode: 400 }
}

async function importContactsSheet(form) {
    const result = await new Promise((resolve) => {
        try {
            headers.authorization = UserService.getToken()
            request.post(`${baseURL}:5970/import_contacts`,{ headers, form },(error,resp) => {
                if(error) {
                    console.error('importContactsSheet',error)
                    resolve({ statusCode: 400, error }) 
                }
                if(resp?.body && !resp.body.startsWith("<!")) {
                    try {
                        const body = JSON.parse(resp.body)
                        resp.body = body 
                    } catch {console.log()}
                    
                    if(resp.body?.statusCode == 401 || resp.statusCode == 401 )
                        UserService.logout()
                    resolve({ ...resp.body})
                } else {
                    resolve({ statusCode: 400, error: 'unknown' })  
                }
            })
        } catch (error) {
            console.error('importContactsSheet',error)
            resolve({ statusCode: 400, error }) 
        }
    }) 
    return result
}

async function sendProductsList(channelId, form) {
    return await doRequest(`${baseURL}:5969/product/select-desired-products/channelId/${channelId}`, 'post', form)
}

async function sendProductsCart(channelId, form) {
    return await doRequest(`${baseURL}:5969/product/send-checkout-cart/channelId/${channelId}`, 'post', form)
}

async function transferWallet(operatorIdFrom, operatorIdTo) {
    return await doRequest(`${baseURL}:5963/operator/transfer-wallet`, 'post', { operatorIdFrom, operatorIdTo })
}

async function updateProduct(channelId, product) {
    // eslint-disable-next-line no-unused-vars
    const { _id, ...productAux } = product
    return await doRequest(`${baseURL}:5969/product/productId/${product._id}/channelId/${channelId}`, 'put', { ...productAux })
}

async function blockContact(contact) {
    return await doRequest(`${baseURL}:5962/channel/blacklist/number`, 'post', contact)
}

async function unblockContact(contact) {
    return await doRequest(`${baseURL}:5962/channel/blacklist/number`, 'delete', contact)
}

async function rdCreateOrganization(organization, channelId) {
    return await doRequest(`${baseURL}:5973/rdstation/organization/channelId/${channelId}`, 'post', organization)
}

async function rdCreateDeal(deal, channelId) {
    return await doRequest(`${baseURL}:5973/rdstation/deal/channelId/${channelId}`, 'post', deal)
}

async function rdCreateTask(task, channelId) {
    return await doRequest(`${baseURL}:5973/rdstation/task/channelId/${channelId}`, 'post', { task })
}

async function rdUpdateTask(task, channelId) {
    return await doRequest(`${baseURL}:5973/rdstation/task/channelId/${channelId}/taskId/${task._id}`, 'put', { task })
}

async function rdCreateProduct(product, channelId) {
    return await doRequest(`${baseURL}:5973/rdstation/product/channelId/${channelId}`, 'post', product)
}

async function rdCreateProductInDeal(product, dealId, channelId) {
    return await doRequest(`${baseURL}:5973/rdstation/product/channelId/${channelId}/dealId/${dealId}`, 'post', product)
}

async function rdCreateContact(contact, channelId) {
    return await doRequest(`${baseURL}:5973/rdstation/contact-crm/channelId/${channelId}`, 'post', contact)
}

async function rdCreateDealStage(dealStage, channelId) {
    return await doRequest(`${baseURL}:5973/rdstation/deal_stages/channelId/${channelId}`, 'post', dealStage)
}

async function rdUpdateDeal(deal, dealId, channelId) {
    return await doRequest(`${baseURL}:5973/rdstation/deal/channelId/${channelId}/dealId/${dealId}`, 'put', deal)
}

async function rdUpdateDealStage(deal, dealId, channelId) {
    return await doRequest(`${baseURL}:5973/rdstation/deal/channelId/${channelId}/dealId/${dealId}`, 'patch', deal)
}

async function rdGetDeal(dealId, channelId) {
    return await doRequest(`${baseURL}:5973/rdstation/deal/channelId/${channelId}/dealId/${dealId}`, 'get')
}

async function rdGetTask(taskId, channelId) {
    return await doRequest(`${baseURL}:5973/rdstation/task/channelId/${channelId}/taskId/${taskId}`, 'get')
}

async function rdGetTasks(channelId, page, filters) {
    return await doRequest(`${baseURL}:5973/rdstation/task/channelId/${channelId}/page/${page}`, 'post', filters)
}

async function rdGetOrganizations(channelId, page) {
    return await doRequest(`${baseURL}:5973/rdstation/organization/channelId/${channelId}/page/${page}`, 'get')
}

async function rdGetOrganizationsSearch(channelId, search) {
    if(search)
        search = search.replace(' ', '%20')
    return await doRequest(`${baseURL}:5973/rdstation/organization/channelId/${channelId}/search/${search}`, 'get')
}

async function rdGetDealsSearch(channelId, search) {
    if(search)
        search = search.replace(' ', '%20')
    return await doRequest(`${baseURL}:5973/rdstation/deal/channelId/${channelId}/search/${search}`, 'get')
}

async function rdGetDealStages(channelId, page) {
    return await doRequest(`${baseURL}:5973/rdstation/deal_stages/channelId/${channelId}/page/${page}`, 'get')
}

async function rdGetDealStagesByPipelineId(channelId, dealPipelineId) {
    return await doRequest(`${baseURL}:5973/rdstation/deal_stages/channelId/${channelId}/dealPipelineId/${dealPipelineId}`, 'get')
}

async function rdGetDealPipelines(channelId, page) {
    return await doRequest(`${baseURL}:5973/rdstation/deal_pipelines/channelId/${channelId}/page/${page}`, 'get')
}

async function rdGetContacts(channelId, page) {
    return await doRequest(`${baseURL}:5973/rdstation/contact/channelId/${channelId}/page/${page}`, 'get')
}

async function rdGetContact(rdStationId) {
    return await doRequest(`${baseURL}:5973/rdstation/contact/rdStationId/${rdStationId}`, 'get')
}

async function rdGetProducts(channelId, page) {
    return await doRequest(`${baseURL}:5973/rdstation/product/channelId/${channelId}/page/${page}`, 'get')
}

async function rdUpdateContact(channelId, rdStationId, contact) {
    return await doRequest(`${baseURL}:5973/rdstation/contact/channelId/${channelId}/contactRdId/${rdStationId}`, 'put', contact)
}

async function rdUpdateProduct(channelId, product, dealId) {
    return await doRequest(`${baseURL}:5973/rdstation/product/channelId/${channelId}/dealId/${dealId}/dealProductId/${product._id}`, 'put', product)
}

async function rdGetAnnotations(channelId, dealId, page) {
    return await doRequest(`${baseURL}:5973/rdstation/annotation/channelId/${channelId}/dealId/${dealId}/page/${page}`, 'get')
}

async function rdCreateAnnotation(annotation, channelId) {
    return await doRequest(`${baseURL}:5973/rdstation/annotation/channelId/${channelId}`, 'post', annotation)
}

async function rdGetUsers(channelId) {
    return await doRequest(`${baseURL}:5973/rdstation/users/channelId/${channelId}`, 'get')
}

async function rdGetCampaigns(channelId, page, form) {
    return await doRequest(`${baseURL}:5973/rdstation/campaigns/channelId/${channelId}/page/${page}`, 'post', form)
}

async function rdGetDealSources(channelId, page, form) {
    return await doRequest(`${baseURL}:5973/rdstation/deal_sources/channelId/${channelId}/page/${page}`, 'post', form)
}

async function createCalendar(e,token){
    return new Promise((resolve, reject) => {
        try {
            let data = JSON.stringify(e);
              
              let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${baseURL}:5963/operator/calendar/operatorId/`+e.operatorId,
                headers: { 
                  'Content-Type': 'application/json', 
                  'Authorization': 'Bearer '+token
                },
                data : data
              };
              
              axios.request(config)
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              });
            
        } catch (error) {
            reject(error)
        }
        
    })
}

async function getFullCalendar(operatorId) {
    return await doRequest(`${baseURL}:5963/operator/calendar/operatorId/${operatorId}`, 'get')
}

async function getCalendar(e,token,page=1){
    return new Promise((resolve, reject) => {
        try {
            const config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${baseURL}:5963/operator/calendar/operatorId/${e.operatorId}/page/${page}`,
                headers: { 
                  'Authorization': 'Bearer '+token
                }
              };
              
              axios.request(config)
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              });
        } catch (error) {
            reject(error)
        }
    })
}

async function updateCalendar(e,token){
    return new Promise((resolve, reject) => {
        try {
            let data = JSON.stringify(e);
              
              let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: `${baseURL}:5963/operator/calendar/id/`+e.id,
                headers: { 
                  'Content-Type': 'application/json', 
                  'Authorization': 'Bearer '+token
                },
                data : data
              };
              
              axios.request(config)
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              });
            
        } catch (error) {
            reject(error)
        }        
    })
}

async function deleteCalendar(id,token){
    return new Promise((resolve, reject) => {
        try {
              let config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: `${baseURL}:5963/operator/calendar/id/`+id,
                headers: { 
                  'Content-Type': 'application/json', 
                  'Authorization': 'Bearer '+token
                },
                
              };
              
              axios.request(config)
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error);
              });
            
        } catch (error) {
            reject(error)
        }        
    })
}

async function getKanbans(channelId, page) {
    return await doRequest(`${baseURL}:5986/kanban/all/channelId/${channelId}/page/${page}`, 'get')
}

async function getKanbansFilter(channelId, filter) {
    return await doRequest(`${baseURL}:5986/kanban/filter/channel/${channelId}`, 'post', { filter })
}

async function createKanban(data) {
    return await doRequest(`${baseURL}:5986/kanban`, 'post', data)
}

async function updateKanban(data) {
    return await doRequest(`${baseURL}:5986/kanban/id/${data._id}`, 'put', data)
}

async function deleteKanban(id) {
    return await doRequest(`${baseURL}:5986/kanban/id/${id}`, 'delete')
}

async function getKanbanSteps(kanbanId) {
    return await doRequest(`${baseURL}:5986/step/idKanban/${kanbanId}`, 'get')
}

async function createKanbanStep(kanbanId, step) {
    return await doRequest(`${baseURL}:5986/step/idKanban/${kanbanId}`, 'post', step)
}

async function updateKanbanStep(step) {
    return await doRequest(`${baseURL}:5986/step/idStep/${step._id}`, 'put', step)
}

async function deleteKanbanStep(id) {
    return await doRequest(`${baseURL}:5986/step/idStep/${id}`, 'delete')
}

async function getCardsByStepId(stepId, channelId) {
    return await doRequest(`${baseURL}:5986/card/stepId/${stepId}/channelId/${channelId}`, 'get')
}

async function getStepById(stepId) {
    return await doRequest(`${baseURL}:5986/step/idStep/${stepId}`, 'get')
}

async function getKanbanById(kanbanId) {
    return await doRequest(`${baseURL}:5986/kanban/id/${kanbanId}`, 'get')
}

async function getCardById(cardId) {
    return await doRequest(`${baseURL}:5986/card/cardId/${cardId}`, 'get')
}

async function getCardsByOperatorId(operatorId) {
    return await doRequest(`${baseURL}:5986/card/operatorId/${operatorId}`, 'get')
}

async function updateCard(card) {
    card.updateHistory = undefined
    return await doRequest(`${baseURL}:5986/card/cardId/${card._id}`, 'put', card)
}

async function getReportKanban(channelId, data) {
    return await doRequest(`${baseURL}:5952/report/kanban/channelId/${channelId}`, 'post', data)
}

async function getReportKanbanPerformance(channelId, data, page) {
    return await doRequest(`${baseURL}:5952/report/kanban-performance/channelId/${channelId}/page/${page}`, 'post', data)
}

async function getHsmTemplates(channelId, type) {
    return await doRequest(`${baseURL}:5989/template/type/${type}/channelId/${channelId}`, 'get')
}

async function getHsmTemplatesFilter(channelId, form) {
    return await doRequest(`${baseURL}:5989/template/filter/channelId/${channelId}`, 'post', form)
}

async function createHSMTemplate(channelId, template) {
    return await doRequest(`${baseURL}:5989/template/channelId/${channelId}`, 'post', template)
}

async function updateHSMTemplate(channelId, template) {
    return await doRequest(`${baseURL}:5989/template/channelId/${channelId}/templateId/${template.id}`, 'put', template)
}

async function deleteHSM(channelId, elementName) {
    return await doRequest(`${baseURL}:5989/template/channelId/${channelId}/elementName/${elementName}`, 'delete')
}

async function sendHSM(channelId, contactNumber, templateName) { // won't open attendance
    return await doRequest(`${baseURL}:5967/message/send_template`, 'post', { channelId, contactNumber, templateName })
}

async function getMessageTags(channelId) {
    return await doRequest(`${baseURL}:5967/tag/channelId/${channelId}`, 'get')
}

async function getMessageTagById(tagId) {
    return await doRequest(`${baseURL}:5967/tag/tagId/${tagId}`, 'get')
}

async function createMessageTag(channelId, tag) {
    return await doRequest(`${baseURL}:5967/tag/channelId/${channelId}`, 'post', tag)
}

async function updateMessageTag(tag) {
    return await doRequest(`${baseURL}:5967/tag/tagId/${tag._id}`, 'put', tag)
}

async function deleteMessageTag(tagId) {
    return await doRequest(`${baseURL}:5967/tag/tagId/${tagId}`, 'delete')
}

async function getCampaigns(channelId) {
    return await doRequest(`${baseURL}:5989/campaign/channelId/${channelId}`, 'get')
}

async function getCampaignsPaginated(channelId, page = 1) {
    return await doRequest(`${baseURL}:5989/campaign/channelId/${channelId}/page/${page}`, 'get')
}

async function getCampaignsFilter(channelId, filters) {
    return await doRequest(`${baseURL}:5989/campaign/filter/channelId/${channelId}`, 'post', { filters })
}

async function getCampaignById(channelId, campaignId) {
    return await doRequest(`${baseURL}:5989/campaign/channelId/${channelId}/campaignId/${campaignId}`, 'get')
}

async function createCampaign(channelId, campaign) {
    return await doRequest(`${baseURL}:5989/campaign/channelId/${channelId}`, 'post', campaign)
}

async function updateCampaign(channelId, campaign) {
    return await doRequest(`${baseURL}:5989/campaign/channelId/${channelId}/campaignId/${campaign._id}`, 'put', campaign)
}

async function deleteCampaign(channelId, campaignId) {
    return await doRequest(`${baseURL}:5989/campaign/channelId/${channelId}/campaignId/${campaignId}`, 'delete')
}

async function sendCampaign(channelId, campaignId) {
    return await doRequest(`${baseURL}:5989/campaign/send-campaign/channelId/${channelId}/campaignId/${campaignId}`, 'get')
}

async function getCampaignStatus(channelId, campaignId) {
    return await doRequest(`${baseURL}:5989/campaign/status/channelId/${channelId}/campaignId/${campaignId}`, 'get')
}

async function cancelCampaign(channelId, campaignId) {
    return await doRequest(`${baseURL}:5989/campaign/cancel/channelId/${channelId}/campaignId/${campaignId}`, 'get')
}

async function getConfigFile() {
    return await doRequest('/config.json', 'get')
}

async function restartBusinessContainer(server, channelId) {
    return await doRequest(`https://gotalk.digital:5152/OO3o3IMq594KuHkrarMrWhTQTQ/${server}/restart/${channelId}`, 'get')
}

async function createBusinessContainer(server, channelId, channelNumber) {
    return await doRequest(`https://gotalk.digital:5152/OO3o3IMq594KuHkrarMrWhTQTQ/${server}/create/${channelId}/${channelNumber}`, 'get')
}

async function recreateBusinessContainer(server, channelId, channelNumber) {
    return await doRequest(`https://gotalk.digital:5152/OO3o3IMq594KuHkrarMrWhTQTQ/${server}/recreate/${channelId}/${channelNumber}`, 'get')
}

async function getInternalChat(operatorIds, operatorId) {
    return await doRequest(`${baseURL}:5967/message/internal/chat`, 'post', { operatorIds, operatorId })
}

async function getInternalChatPerDay(operatorIds, operatorId, day) {
    return await doRequest(`${baseURL}:5967/message/internal/chat/day`, 'post', { operatorIds, operatorId, day })
}

async function getInternalChats(operatorId) {
    return await doRequest(`${baseURL}:5967/message/internal/chats`, 'post', { operatorId })
}

async function getInternalMessages(operatorIds) {
    return await doRequest(`${baseURL}:5967/message/internal/messages`, 'post', { operatorIds })
}

module.exports = {
    authenticate, getOperatorToken, getOperatorTokenAzure,
    getChannel, getChannels, getSecondaryChannels, getChannelsFilter, getChannelsGotQr, getChannelsGupshup, loadMessages, loadGroupMessages, loadGroupMessagesWeek, loadGroupPreviousMessages, getMenus, getMenuTemplates, getMenuTemplatesPagination, getMenu, getMenuOptions, getMenuFromTopmenu, getMenuRefs, 
    getContacts, getRetrieveContacts, getAllContacts, getContactsFilter, getContactsSearch, getContactsCountFromAllChannels, getContactsTotal, getContact, getBirthdayContacts, 
    getReportAttendance, getReportAttendanceFull, getReportSales, getReportAttendanceCount, getReportTabulation, getReportTabulationFull, getReportTabulationCount, getFinishedAttendancesCount, getFinishedAttendancesPagination, getReportAverage, getReportAverageFull, getReportAverageCount, getReportCampaign,
    getChannelConfig, getTabulations, getDepartments, getAttendanceById, getAttendances, getAttendancesPagination, getAttendancesPaginationByStatus, getAttendancesCount, getContactsWithoutAttendance, getOldAttendances, searchAttendances, getContactHistory, getProtocolMessages, getAttendanceMessages,
    getOperator, getOperators, getOperatorsCountFromAllChannels, getOnlineOperators, getOperatorData, getManagers, getManager, getCommunicationsIndex, getTME, getTMA, getTopMenus, getApiServers, getAttendancesFollow, getAttendancesFollowCount, getAttendancesFollowPagination, getMessageTemplates, getGroups,
    getOperatorsGroupAccess, getGroupQuantity, getOperatorGroupsQuantity, getAttendanceQuantity, getTags, getAllTags, getQuickAnswers, getQuickAnswersByDepartments, getAttendanceLastMessage, getAttendanceUnreadCount, getOperatorUnreadCount, getActiveCommunicationTimeLeft, getCatalogCategories, getCatalogCategoryById,
    getCatalogProductsByKeyword, getCatalogProductsByCategoryLowestPrice, getCatalogProducts, getCatalogProductsPagination, getCatalogProductByProductId, getAllProductsCount, getReportNewContacts, getReportSurveyRatingsData, getMessagesTotalSentReceived, getSurveyTopOperator, getSurveyTopOperators, 
    getChannelHasSecondaryChannels, getServersConfig, getAccessLog, getGupshupTraffic,
    getStates,
    deleteChannel, deleteOperator, deleteDepartment, deleteMenu, deleteContact, deleteTabulation, deleteManager, deleteTag, deleteQuickAnswer,
    createUser, createDepartment, createSecChannel, createMenu, createContact, createTabulation, createTag, createQuickAnswer, 
    updateUser, updateChannel, updateOperator, updateDepartment, updateMenu, addMenuOption, updateSections, updateButtons, updatePresentation, updateContact, updateContactName, updateContactEmail, updateContactCode, updateContactTag, updateContactResponsibleOperator, updateChannelConfig, updateTabulation,
    updateManager, updateOperatorGroupAccess, updateTag, updateQuickAnswer, updateGroupField, updateCatalogCategory, GetOrderById,
    retrieveAttendance, changeOperatorStatus, generateAuthToken, blockChannel, upload, importContactsSheet, sendProductsList, sendProductsCart, transferWallet, updateProduct, blockContact, unblockContact,
    rdCreateOrganization, rdCreateDeal, rdCreateTask, rdUpdateTask, rdCreateProduct, rdCreateProductInDeal, rdCreateContact, rdCreateDealStage, rdUpdateDeal, rdUpdateDealStage, rdGetDeal, rdGetTask, rdGetTasks, rdGetOrganizationsSearch, rdGetDealsSearch, rdGetOrganizations, rdGetDealStages, rdGetDealStagesByPipelineId, rdGetDealPipelines, rdGetContact, rdGetContacts, rdGetProducts, rdUpdateContact, rdUpdateProduct,
    rdGetAnnotations, rdCreateAnnotation, rdGetUsers, rdGetCampaigns, rdGetDealSources, createCalendar, getCalendar, getFullCalendar, updateCalendar, deleteCalendar,
    getKanbans, getKanbansFilter, createKanban, updateKanban, deleteKanban, getKanbanSteps, createKanbanStep, updateKanbanStep, deleteKanbanStep, getCardsByStepId, getStepById, getKanbanById, getCardById, getCardsByOperatorId, updateCard, getReportKanban, getReportKanbanPerformance,
    getHsmTemplates, getHsmTemplatesFilter, createHSMTemplate, updateHSMTemplate, deleteHSM, sendHSM,
    getMessageTags, getMessageTagById, createMessageTag, updateMessageTag, deleteMessageTag,
    getCampaigns, getCampaignsPaginated, getCampaignsFilter, getCampaignById, createCampaign, updateCampaign, deleteCampaign, sendCampaign, getCampaignStatus, cancelCampaign,
    getConfigFile, restartBusinessContainer, createBusinessContainer, recreateBusinessContainer,
    getInternalChat, getInternalChatPerDay, getInternalChats, getInternalMessages
}